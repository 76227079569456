import React, {useContext, useEffect} from 'react';
import { WispContext } from './WispProvider';
import { DocManager } from './components/DocManager';
import { SiteProps } from './SiteProps';
import { SitePub } from './components/SitePub';

export const BookSettings = (props) => {

	var wispData = useContext(WispContext);

	useEffect(() => {

	}, []);


	var site = props.site;
	if (!site) {
		return(<div/>);
	}
	var user = wispData.publicState.wispUser;
	var userStripeInfo = wispData.publicState.userStripeInfo;

	var titleSection;
	var statusSection;
	var shareSection;
	var createButton;
	if (props.new) {
		titleSection = (
			<React.Fragment>
				<div className='panel_name' style={{marginLeft:'2px'}}>Create Your</div>
				<div className='panel_title' id='set_title'>New Story</div>
			</React.Fragment>
		);
	}
	else{
		titleSection = (
			<React.Fragment>
				<div className='panel_name' style={{marginLeft:'2px'}}>{site.name}</div>
			</React.Fragment>
		);
	}

	var storyBut = false;
	// is props.site an event?
	if (user.permissions && user.permissions.length > 0) {
		if (user.permissions[0] === 'wisp_admin') {
			storyBut = true;
		}
	}

	return (
		<div className='bookSettingsContainer'>
			{titleSection}
			{statusSection}
			<SiteProps 
				new={props.new} 
				docMaps={props.docMaps} 
				site={props.site}
				siteName='story'
				setAccess={false}
				setDates
				eventType
				/>
			<DocManager
				new={props.new}
				docMaps={props.docMaps}
				site={props.site}
				azure_client_id='f8dc80ed-416f-4061-ac41-5b894413cc08'
				type='Story'
				label='Sheets'
				story={storyBut}
				accept='.pdf, .docx, .xlsx, .jpg, .jpeg, .png, .mov'
				addContent
				/>
			{/* <SitePub 
				new={props.new} 
				docMaps={props.docMaps} 
				site={props.site}
			/> */}
			<div style={{height: '30px'}} />
			{shareSection}
			{createButton}
		</div>
	);

	/*




	if(!newBook){
		var disp = 'display:none;';
		if(ts_teams[ts_nCurT + "_authorFee"] != '0')
			disp = '';
		<div id='set_banki_div' style={{marginTop:30px;position:relative;" + disp + "'>";
			<div className='set_sect_stat'>" + getStripeCheck() + "</div>
			<div className='wisp_app'><span style={{marginLeft:25px;'>Bank Information</span></div>
			<div className='wisp_app_list'>";
				<div id='set_stripeButDiv'></div>
			</div>
		</div>
	}

	// FILE MANAGEMENT


	// ADVANCED FEATURES
	<div style={{marginTop:30px;position:relative'}}>
		if(!newBook)
			<div className='set_sect_stat'>" + getStatCheck() + "</div>
		<div className='wisp_app'><span style={{marginLeft:25px;'>Advanced Features</span></div>
		<div className='wisp_app_list'>";
			str += drawTeamOptions(getApp(),"teamOpts");
			if(!newBook){
				<div id='setAPropsButDiv' style={{margin:20px 0px 0px 0px;position:relative;display:none'}}>
					<div className='webt_but webt_ui_back_2 webt_ui_color_1 wisp_in_mid' style={{padding:8px;fontSize:16px;width:200px;textAlign: center;cursor:pointer;margin:20px 0px 0px 0px;' onClick={setTeamVals(\"teamOpts\");'>Save</div>
				</div>
			}
		</div>
	</div>

	if(!newBook){
	}

	if(newBook){
		<div style={{margin:20px 0px 30px 0px;position:relative'}}>
			<div className='wisp_alert' id='createT_alert_div'>";
				<div className='arrow'></div>
				<p id='createT_alert_p'></p>";
			</div>
			<div className='webt_but webt_ui_back_2 webt_ui_color_1' id='set_create_but' style={{display:'inline-table',verticalAlign:middle;padding:8px;fontSize:16px;width:200px;textAlign: center;cursor:pointer;margin:20px 0px 0px 0px;' onClick={createTeam();'>Create " + getAppKeyword() + "</div>
			<div style={{display:'inline-table',verticalAlign:middle;padding:8px;fontSize:16px;width:100px;cursor:pointer;marginLeft:20px;marginTop:15px;' onClick={mainPanHome();'>cancel</div>
		</div>
	}
	*/
}
