import React, {useContext, useEffect} from 'react';
import { fetchGet, getWispUrl, wisp_urls } from './util/fetch.js';
import { getFirstPageDoc } from './util/site.js';
import {fileType} from './util/document.js';
import {WispDoc} from './components/WispDoc.js';
import {WispNav} from './components/WispNav';
import { NextLink } from './components/NextLink';
import {WispContext} from './WispProvider';
import {BookSettings} from './BookSettings';
import {PageView} from './PageView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const SiteView = (props) => {

    var wispData = useContext(WispContext);
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [curPageId, setCurPageId] = React.useState(0);
	const [curView, setCurView] = React.useState(null);
	const [navOpen, setNavOpen] = React.useState(!wispData.mobileView);
	const [docMapsLoaded, setDocMapsLoaded] = React.useState(false);
	const [refreshingDocMaps, setRefreshingDocMaps] = React.useState(false);

	// pdf stuff
	const [numPages, setNumPages] = React.useState(null);
	const [pageNumber, setPageNumber] = React.useState(1);

    useEffect(() => {
        var url = getWispUrl(wisp_urls.site_pages_get, props.site.id);
		fetchGet(url, getPagesResult, wispData);	// will get one page with all the docBoxMaps, so probably redundant we're calling getDocMaps next
		setRefreshingDocMaps(true);
		var url = getWispUrl(wisp_urls.site_docMaps_get, props.site.id);
		fetchGet(url, getDocMapsResult, wispData, true);
	}, [curPageId]);		// curPageId won't change for a site so useEffect will only get called once per site loading

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

    function getPagesResult(success, data) {
        if (success && props && props.site) {
            // put the site pages into the dictionary of site pages
            var curValue = wispData.sitePages;
            if (typeof curValue != 'undefined') {
                curValue[props.site.id] = data;
            } else {
                var id = props.site.id;
                curValue = { id: data };
            }
            wispData.setSitePages(curValue);

            // this needs work, but for now if there's only one page then load the page docs
            if (data.length >= 1) {
                setCurPageId(data[0].id);	//hooks suck - if the fetchGet below doesn't call the backend then 
            }
        }
	}

	function getDocMapsResult(success, data) {
		if (success) {
			setRefreshingDocMaps(false);
			wispData.setSiteDocMaps(data);
			setDocMapsLoaded(true);
			// figure out the view - if member should go to first doc in list
			if (props.docId) {
				var map = getMapFromDocId(data, props.docId);
				selPanDoc(map);
			} else if (props.site && (props.site.user_access.role !== 'admin' && props.site.user_access.role !== 'author')) {
				var map = getFirstPageDoc(curPageId, data);	// can't count on wispData.siteDocMaps being set yet
				selPanDoc(map);
			} else if (props.site && (props.site.user_access.role == 'admin' || props.site.user_access.role === 'author')) {
				setView({ type: 'settings', value: {} });
			}
			setForceUpdate(forceUpdate + 1);

		}
	}

	function getMapFromDocId(maps, docId) {
		var themap;
		maps.forEach((map) => {
			if (map.doc_id === parseInt(docId)) {
				themap = map;
			}
		});
		return themap;
	}

/*    function getDocsResult(success, data, pageId) {
        if (success) {
            // put the page docs into the dictionary of page docs
            // this needs work
            var curValue = wispData.pageDocMaps;
            if (typeof curValue != 'undefined') {
                curValue[pageId] = data;
            } else {
                curValue = { pageId: data };
            }
            wispData.setPageDocMaps(curValue);

			// figure out the view - if member should go to first doc in list
			if (props.docId) {
				var map = getMapFromDocId(data, props.docId);
				selPanDoc(map);
			} else if (props.site && (props.site.user_access.role !== 'admin' && props.site.user_access.role !== 'author')) {
				var map = wispData.pageDocMaps[pageId][0];
				selPanDoc(map);
			}
			setForceUpdate(forceUpdate + 1);
        }
	} */

	function selPanDoc(map) {
		if (map) {
			if (!wispData.docContent[map.doc_id] && fileType(map.filename) != 'pdf' && map.url === null) {
				var url = getWispUrl(wisp_urls.document_get, map.doc_id);
				if (wispData.mobileView) {
					url += "?view=mobile";
				} else {
					url += "?view=desktop";
				}
				url += "&format=html";
				// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
				fetchGet(url, getDocResults2, wispData, true, map, {style: 'blog' }, 'html');
			} else {
				setView({ type: 'document', value: map });
				setTimeout(function () { window.scrollTo(0, 0); }, 100);		
			}
		}
	}

	function getDocResults2(success, data, map) {
		if (success) {
			var content = wispData.docContent;
			content[map.doc_id] = data;
			wispData.setDocContent(content);
			setView({ type: 'document', value: map });
			setTimeout(function () { window.scrollTo(0, 0); }, 100);		
		}
	}

    function setView(view) {
        setCurView(view);
    }

	function wisp_popup_toggle() {
		setNavOpen(!navOpen);
	}

	function refreshDocMaps() {
		console.log('Refreshing doc maps');
		setRefreshingDocMaps(true);
	}

	var guardian;
	if (wispData.mobileView) {
		var guardianIcon = faArrowRight;
		var gclass = 'closed';
		if (navOpen) {
			guardianIcon = faArrowLeft;
			gclass = 'open';
		}
		guardian = (
			<div className={gclass + ' wisp_guardian'} onClick={wisp_popup_toggle}>
				<div className='wisp_m_icon'>
					<FontAwesomeIcon icon={guardianIcon} />
				</div>
			</div>
		);
	}

	var siteDocMaps = wispData.siteDocMaps;

	// check that siteDocMaps match the site we're viewing
	// follow link to another site and go back and sitedocmaps don't reload
	/* if (siteDocMaps && siteDocMaps[0]) {
		console.log('siteDocMaps = ' + siteDocMaps[0].site_id + ', ' + props.site.id);
		if (siteDocMaps[0].site_id != props.site.id) {
			if (refreshingDocMaps === false) {
				refreshDocMaps();
			}
		}
	} */

	var docView;
    if (curView) {
		if (curView.type === 'settings' && props.site && (props.site.user_access.role === 'admin' || props.site.user_access.role === 'author')) {
			docView = (
				<BookSettings site={props.site} navOpen={navOpen} docMaps={wispData.siteDocMaps} />
			);
		} else if (docMapsLoaded && siteDocMaps.length === 0) {
			docView = (
				<div className='noContentDiv'>
					<div>No content is currently available for <i>{props.site.name}</i>.</div>
					<div style={{ marginTop: '20px' }}> Please check back later or contact the book author.</div>
				</div>
			);
		} else if (curView.type === 'document'){
			if (curView.value.url != null && curView.value.url != '') {
				var cls = '';
				if (navOpen) {
					cls = 'float';
				}
				var url = curView.value.url;
				var i = url.lastIndexOf('/');
				if (i > 0) {
					url = 'https://www.youtube.com/embed' + url.substring(i);
				}
				docView = (
					<div id='wispPageContId' className={'wispPageContainer wispBlog ' + cls} style={{marginTop: '80px', width: '100%', height: '100%'}}>
						<iframe width='100%' height='100%' style={{ minHeight: '500px' }} src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
						<NextLink siteDocMaps={wispData.siteDocMaps} value={curView.value} curPageId={curPageId} selPanDocFN={selPanDoc} />
					</div>
				)
			} else if (fileType(curView.value.filename) !== 'pdf') {
				docView = (
					<PageView value={curView.value} navOpen={navOpen} curPageId={curPageId} selPanDocFN={selPanDoc} />
				)
			} else {
				docView = (
					<WispDoc value={curView.value} navOpen={navOpen} loadFN={onDocumentLoadSuccess} numPages={numPages} />
				);
			}
		}
	}

	var nav;
	if (navOpen) {
		nav = (
			<WispNav site={props.site} curPageId={curPageId} setViewFn={setView} curView={curView} selPanDocFN={selPanDoc} type='Story' />
		);
	}

    return (
        <div className='siteViewCont'>
			{nav}
			{guardian}
            {docView}
        </div>
    );
}
  