import React, {useContext, useEffect} from 'react';
import {WispContext} from './WispProvider';
import { WispNotification } from './components/WispNotification';
import { getWispUrl, wisp_urls } from './util/fetch.js';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// bcls - background color class
// d - inline-block, etc
// w - width of button
// js - onclick javascript
// m - margins
// mes - button message

export default function CodePopup(props) {

    var wispData = useContext(WispContext);

    /* const [signInState, setSignInState] = React.useState({
        showForgotPassword: false,
    }) */
    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});

    /* function setShowForgotPassword(val) {
        if (signInState.showForgotPassword != val) {
            setSignInState((prevState) => {
                return {
                    ...prevState,
                    showForgotPassword: val,
                }
            });
        }
    } */

    useEffect(() => {
        $("#email").focus();
        // testLoad();
    }, []);

    function testLoad() {
        var url = 'http://127.0.0.1:8000/document/5140112245522432?format=cells';
        fetch(url, {method: 'GET', mode: 'cors'})
        .then(function(response) {
            return response.json();
        })
        .then(data => {
            var i = 0;
            // successFN(data);
        })
        .catch((error) => {
            var i = 0;
            // errorFN(error);
        });
	}

	function deleteSite() {
		// Validate inputs
		var confirmText = $("#email").val();
		if (confirmText.toUpperCase() !== props.site.name.toUpperCase()) {
			setAlertMessage({ text: 'Please type ' + props.site.name + ' to confirm', type: 'error' });
			return;
		}

		var token = wispData.token;
		var headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
		}
		var url = getWispUrl(wisp_urls.site_delete, props.site.id);
		console.log('url = ' + url);
		fetch(url, { method: 'POST', mode: 'cors', headers })
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				console.log('success = ' + data);
				props.closeFN(true);
			})
			.catch((error) => {
				console.error('Error:', error);
			});

	}


	function closePopup() {
		props.closeFN(false);
    }

    function getCommonHTML() {
        return (
            <React.Fragment>
                <div>// docId is doc's id in TEngine (provided below)</div>
                <div>// view is either 'desktop' or 'mobile'</div>
                <div>// style is 'classic' (render like doc appears in Word) or 'blog' (some styles are set using css)</div>
                <div>function setTEngineHtmlToDiv(docId, view, style) {"{"}</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;var url = 'https://api.tengineserver.online/document/' + docId + '?view=' + view + '&format=html';</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;fetch(url, {"{method: 'GET', mode: 'cors', headers: {style: style}})"}</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;.then(function(response) {"{"}</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return response.text();</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;{"})"}</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;.then(data => {"{"}</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;document.getElementById(docId).innerHTML = data;</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;{"})"}</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;.catch((error) => {"{"}</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;console.log('error getting ' + docId + ': ' + error);</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;{"});"}</div>
                <div>{"}"}</div>
                <div>&nbsp;</div>        
            </React.Fragment>

        );
    }

    /* function emailInfo() {

    }

    function referralInfo() {

    } */

    var codeCommon;
    var codeHTML;
    var cssCommon;
    var viewCode;
    var htmlTitle;
    var cssTitle;
    var cellCode;
    viewCode = (
        <div>setTEngineHtmlToDiv({props.codeDoc.id}, 'desktop', 'blog');</div>
    );
    htmlTitle = "HTML for " + props.codeDoc.name;
    codeHTML = (
        <React.Fragment>
            <div>{"<"}div id='{props.codeDoc.id}' className='wispBlog' /{">"}</div>
        </React.Fragment>
    );

    if (props.codeDoc.name.indexOf('docx') > 0 || props.codeDoc.name.indexOf('jpg') > 0 || props.codeDoc.name.indexOf('png') > 0) {
        codeCommon = getCommonHTML();

        if (props.codeDoc.name.indexOf('docx') > 0) {
            cssCommon = (
                <React.Fragment>
                    <div>.wispClassic .pageDivider {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;background: #EEE;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;height: 10px;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;width: 100%;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;margin: 5px 0px;</div>
                    <div>{"}"}</div>

                    <div>.wispBlog .pageDivider {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;height: 2px;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;margin: 10px 30px;</div>    
                    <div>{"}"}</div>

                    <div>.wispBlog .wispImage {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;object-fit: cover;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;max-height: 700px;</div>
                    <div>{"}"}</div>

                    <div>.wispBlog .docx_p_normal {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;font-size: 16px;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;font-family: Arial;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;line-height: 180%;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;color: #525252;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;margin-bottom: 10px;</div>
                    <div>{"}"}</div>

                    <div>.wispBlog docx_p_subtitle {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;font-size: 14px;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;font-family: Arial;</div>
                    <div>{"}"}</div>

                    <div>.wispBlog .docx_p_heading {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;font-size: 24px;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;font-family: Arial;</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;margin: 10px 0px;</div>
                    <div>{"}"}</div>

                    <div>.wispBlog #pub_doc {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;padding: 30px;</div>
                    <div>{"}"}</div>

                    <div>.wispBlog .docx_embed_video {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;min-height: 600px;</div>
                    <div>{"}"}</div>

                </React.Fragment>
            );
            cssTitle = "Example CSS";
        }
    } else {
        cellCode = (
            <React.Fragment>
				<h3>Javascript to get cells from {props.codeDoc.name}</h3>
                <div style={{position:'relative', marginTop: '20px'}}>
                    <div>var url = 'https://api.tengineserver.online/document/{props.codeDoc.id}?format=cells';</div>
                    <div>fetch(url, {"{method: 'GET', mode: 'cors'})"}</div>
                    <div>.then(function(response) {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;return response.json();</div>
                    <div>{"})"}</div>
                    <div>{".then(data => {"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;var cells = data['cells'];</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;{"for (var row = 0;row < cells.length;row++) {"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;var rowVals = cells[row];</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"for (var col = 0; col < 10; col++) {"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;console.log('row ' + row + ', col ' + col + ' = ' + getColVal(rowVals, col));</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"}"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;{"}"}</div>
                    <div>{"})"}</div>
                    <div>.catch((error) => {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;console.log('error getting ' + {props.codeDoc.id} + ': ' + error);</div>
                    <div>{"});"}</div>
                </div>
            </React.Fragment>
        );
        
        codeCommon = (
            <React.Fragment>
                {getCommonHTML()}
                <div>function getColVal(row, col){"{"} </div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;for (var i = 0;i {"<="} col; i++) {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;var nCol = getColNum(row, i);</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (nCol === col) {"{"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return row['cols'][i]['v'];</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"}"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;{"}"}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;return '';</div>
                    <div>{"}"}</div>
                    <div>function getColNum(row, col) {"{"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;var nCol = -1;</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;var c = row['cols'][col];</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;if (c) {"{"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;var cVal = c['c'];</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (cVal) {"{"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cVal = cVal.charCodeAt(0);</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (col {"<"} 26) {"{"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;nCol = cVal - 65;</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"}"} else {"{"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;nCol = row['cols'][col]['c'].charCodeAt(1) - 65;</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"}"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"}"}</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;{"}"}</div>
                    
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;return nCol;</div>
                    <div>{"}"}</div>
        
            </React.Fragment>
        );

    }
    
    return (

        <React.Fragment>
            <div id='get_code_div'>
                <div className="close_pos_float_30" onClick={closePopup}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
				<h3>Javascript to view {props.codeDoc.name}</h3>
                <div style={{position:'relative', marginTop: '20px'}}>
                    {viewCode}
                </div>
                {cellCode}
				<h3>{htmlTitle}</h3>
                <div style={{position:'relative', marginTop: '20px'}}>
                    {codeHTML}
                </div>
				<h3>Common Code for all Documents</h3>
                <div style={{position:'relative', marginTop: '20px'}}>
                    {codeCommon}
                </div>
				<h3>{cssTitle}</h3>
                <div style={{position:'relative', marginTop: '20px'}}>
                    {cssCommon}
                </div>
                <div style={{position:'relative'}}>
                    <div className='button' id='signin_but' onClick={() => closePopup()} style={{width:'100%'}}>Ok</div>
                </div>
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
