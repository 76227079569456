import React, {useContext, useEffect} from 'react';
import { Footer } from './components/Footer';
import { HTMLRenderer } from './components/HTMLRenderer';

export const TermsView = (props) => {   
    return (
        <div>
            <HTMLRenderer
                url='https://api.tengineserver.online/document/6314166050619392?style=classic'
            />
            <Footer/>
        </div>
    );
}
