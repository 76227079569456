import React, {useContext, useEffect} from 'react';
import Header from './components/Header';
import CreateAccountPopup from './popups/CreateAccountPopup';
import SignInPopup from './popups/SignInPopup';
import {SubscribePopup} from './popups/SubscribePopup';
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import {loadUserSites} from './util/user.js';
import './css/site-3.9.css';
import './css/wisp-doc.css';
import {WispContext} from './WispProvider';
import {wispHistory} from './components/WispHistory';
import $ from 'jquery';
import EasyLogo from './images/tengine_logo.png';
import EasyIcon from './images/tengine_logo.png';

var loadingUserSites = false;

export const BaseLayout = (props) => {
    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;


    useEffect(() => {

        if (!wispData.checkedToken) {
            var token = wispData.token;
            var userId = publicState.wispUserId;
            if (!wispData.token) {
                token = window.localStorage.getItem("wisp_token");
                if (token) {
                    wispData.setToken(token);
                }
                userId = window.localStorage.getItem("user_id");
                if (userId) {
                    wispData.setWispUserId(userId);
                }
            }

            if (token && token != '' && userId && userId != '') {
                verifyToken(token, userId);
            } else {
                wispData.setCheckedToken(true);
            }
            console.log('user id = ' + userId);
        }

        if (publicState.wispUser && !wispData.userSitesLoaded && !loadingUserSites) {
            loadingUserSites = true;
            console.log('baselayout: loadUserSites');
            loadUserSites(wispData, publicState.wispUser.id, publicState.wispUser.id, wispData.token, getSitesResult, false, userId);
		}

		wispData.setWindowSize({w: window.innerWidth, h: window.innerHeight})
		if (window.innerWidth <= 800) {
			wispData.setMobileView(true);
        }

	}, [publicState.wispUser]);

    function verifyToken(token, userId) {
        var url = wisp_urls.user_get + userId + '/';
        console.log('going to check token with url = ' + url + ' and token = ' + token);

        var headers = {};
        headers['user-id'] = userId;
        headers['Authorization'] = 'Bearer ' + token;
            
        fetch(url, {method: 'GET', mode: 'cors', headers})
        .then(function(response) {
            return response.json();
        })
        .then(data => {
            checkTokenResult(true, data);
        })
        .catch((error) => {
            checkTokenResult(false, error);
        });
    }

	function getSitesResult(success, data, userId) {
		console.log('GOT SITES');
        console.log('setting usersitesloaded');
        if (success && (data.detail !== 'invalid token')) {
            wispData.setUserSites(data);
            wispData.setUserSitesLoaded(true);
            // this was commented out but we need it to redirect to the user dashboard after their site is created (normally when account is created but can be when signing in (if has no site))
            if (userId) {
                 wispHistory.push('/' + userId);
            }
        }
    }

    function checkTokenResult(success, data) {
        if (success) {
            if (data.detail && (data.detail === 'invalid token') || (data.detail === 'Not Found')) {
                wispData.setValidToken(false);
            } else if(data['token'] === null) {
                console.log('no token in user');
                wispData.setValidToken(false);
            } else {
                wispData.setValidToken(true);
                console.log('setting wispUser - ' + JSON.stringify(data));
                wispData.setWispUser(data);
            }    
        }
        wispData.setCheckedToken(true);
    }

    function merchFN() {
        window.location = 'https://store.crazydoes.com';
    }

    function logoClick() {
        wispHistory.push('/');
    }

    function eventFN() {
        var pageElement = document.getElementById("crazyPanel2");
        var positionY = pageElement.offsetTop;
        window.scrollTo(0, positionY-80);        
    }

    function promoClick() {
        window.location = 'https://store.crazydoes.com';
    }

    function onSignIn(userId, sites) {
        // this method doesn't get called until all the signin state variables are setup - it does that MAGIC by calling this from SigninPopup useEffect
        // got any sites? if not, then create the excel site which will hold all your excel docs
        if (sites.length === 0) {
            createFN(userId, wispData.publicState.wispUser);
        } else {
            wispHistory.push('/' + userId);
        }
    }

    function createDone(userId, user) {
        createFN(userId, user);
    }

	function createFN(userId, user) {
    	var siteData = {
			access: 'public',
			agreed: false,
			app_id: process.env.REACT_APP_ID,
			author_fee: 0,
			date_start: '',
			date_end: '',
			description: user.first_name + '\'s Excel sheets',
			name: user.first_name + '\'s Excel sheets',
			price: 0,
			url: user.wisp_id,
		};
        var tToken;
        wispData.setToken(prevValue => {tToken = prevValue; return (prevValue)});
		var headers = {
			'Authorization': 'Bearer ' + tToken,
			'Content-Type': 'application/json',
			'user-id': user.id,
			'boxes': 'title,library',
			'app-id': process.env.REACT_APP_ID,
			'app-data': {},
		}
		var url = wisp_urls.site_create;
		fetch(url, { method: 'POST', mode: 'cors', headers: headers, body: JSON.stringify(siteData) })
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				// reload sites here and then redirect to new site
                loadUserSites(wispData, userId, userId, wispData.token, getSitesResult, true, userId);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
//		wispData.setNewSite(site);
	}    

    function faqsClick() {
        wispHistory.push('/faqs');
    }

    var subscribePopup;
    if (publicState.showSubscribe) {
        subscribePopup = (
            <SubscribePopup targetName='WispBook'/>
        )
    }
    let createAccountPopup;
    if (wispData.showCreateAccount) {
        createAccountPopup = (
            <CreateAccountPopup title='New TEngine account!' successFN={createDone} />
        );
    }
    let signInPopup;
    if (wispData.showSignin) {
        signInPopup = (
            <SignInPopup createOption onSignInFN={onSignIn} />
        );
    }

    var layout = (
        <div />
    );

    var logo = EasyLogo;
    if (wispData.mobileView) {
        logo = EasyIcon;
    }

    var promo;
    var setEventFN;
    var homeUrl = process.env.REACT_APP_URL + '/';
    var top = '0px';


    if (wispData.checkedToken === true) {
        layout = (
            <React.Fragment>
                <Header title={props.title} limit={15} top={top} logo={logo} logoAlt="TEngine" logoClickFN={logoClick} iconText='TEngine' home='Home' />
                {createAccountPopup}
                {signInPopup}
                {subscribePopup}
                <div className="pageBody">
                    {props.children}
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
			{layout}

        </React.Fragment>
    );
}
  