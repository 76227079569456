import React, {useContext, useEffect} from 'react';
import { Footer } from './components/Footer';
import { HTMLRenderer } from './components/HTMLRenderer';

export const PrivacyView = (props) => {

//             <iframe className='faqDocDiv' src="https://webtotality-my.sharepoint.com/personal/jefft_webtotality_com/_layouts/15/Doc.aspx?sourcedoc={344e0485-c16b-4583-b4ae-f1313da4abe4}&amp;action=embedview" width="100%" height="100%" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> document, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>

    return (
        <div>
            <HTMLRenderer
                url='https://api.tengineserver.online/document/5658410443866112?style=classic'
            />
           <Footer/>
        </div>
    );
}