import React, {useContext, useEffect} from 'react';
import './App.css';
import {BaseLayout} from './BaseLayout';
import {SiteView} from './SiteView.js';
import { Home } from './Home.js';
import HomeUser from './HomeUser.js';
import PasswordReset from './PasswordReset.js';
import AccountView from './AccountView.js';
import EventView from './EventView.js';
import {FilePickerView } from './FilePickerView.js';
import {SupportView} from './SupportView.js';
import {FAQView} from './FAQView.js';
import {TermsView} from './TermsView.js';
import {PrivacyView} from './PrivacyView.js';
import {wispHistory} from './components/WispHistory.js';
import { AzureAuth } from './components/AzureAuth.js';
import {WispContext} from './WispProvider.js';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { stripDomainFromUrl } from './util/base.js';
import { getSiteUrlFromUrl, getDocIdFromUrl, getSiteFromUrl } from './util/site.js';
import { fetchGet, wisp_urls } from './util/fetch.js';
import './css/webtotality_ui.css';
import './css/webtotality.css';
import './css/easy.css';
import './css/common.css';

// var wisp_user_id;

var gettingSiteInfo = 'init';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_DATA_KEY);
// var settingOauthCode = false;

export const App = (props) => {
  var wispData = useContext(WispContext);
  var publicState = wispData.publicState;

  // having trouble getting these to work - had to add crap to WispProvider
    const [pathName, setPathName] = React.useState('');

  useEffect(() => {
    wispHistory.listen(({ action, location }) => {
      // for some reason action and location are undefined...
      // in any case, have to change a prop to get the app compmonent to rerender
      if (action) {
        console.log(`The last navigation action was ${action}`);
      }
      if (location) {
        console.log('location = ' + location.pathname);
        setPathName(location.pathname);
      } else {
        console.log('no location');
        setPathName(window.location.href);
      }

    });
  }, []);

  function getSiteInfo(siteUrl){
      console.log('App.js: get site by URL');
      gettingSiteInfo = 'getting';
      /* if (siteUrl.indexOf('azure_auth') > 0) {
          var i = 0;
          alert("F UP!");
      } else { */
      var url = wisp_urls.site_get_by_url;
      var force = true;
      if (wispData.siteInfo && wispData.siteInfo.url === siteUrl) {
          force = false;
      }
      url += '?url=' + siteUrl;
      fetchGet(url, getSiteResult, wispData, force, null);   // if you don't have true then won't reload even though 'url' param is different
      // }
  }

  function getSiteResult(success, data) {
    if (data === 'fetching') {  // not sure why it does this...
      return;
    }
    if (success) {
      console.log('setting siteInfo to ' + JSON.stringify(data));
      wispData.setSiteInfo(data);
    }    
    console.log('setting gettingSiteInfo to got');
    gettingSiteInfo = 'got';
  }

  function getEventFromUrl(href) {
    var event = null;

    // href = stripDomainFromUrl(href);
  
    var i = href.indexOf('event/');
    if (i >= 0) {
      event = href.substring(i+6);
    }
    return event;
  }

  function getUserIdFromUrl(href) {
    var userId = null;
    var i = href.indexOf('/');
    var i2 = href.indexOf('?');
    if (i > 0) {
      userId = href.substring(0,i);
    } else if (i2 > 0) {
      userId = href.substring(0,i2);
    } else {
      userId = href;
    }

    // test server fix
    if (userId === 'judixuk3mqpacmlxhfi1') {
      userId = 'JUDIXUK3MQPACMLXHFI1';
    }
/*    if (publicState.validToken && publicState.wispUser) {
      href = stripDomainFromUrl(href);
      var str = '/' + publicState.wispUser.id.toLowerCase();
      var i = href.indexOf(str);
      if (i >= 0) {
        userId = publicState.wispUser.id;
      }
    }
*/
    return userId;
  }

  // console.log('href = ' + window.location.href);
  var pathname = wispHistory.location.pathname;
  var href = window.location.href;
  var hrefLower = href.toLowerCase();  // watch out - this will kill caps in azure_auth code, etc

    if (hrefLower.indexOf('http://localhost') === 0) { // to handle azure's stupid requirement to only redirect to http://localhost
        window.location.href = 'http://app.localhost' + href.substring(16);
        return;
    } else if (hrefLower.indexOf('https://tengineserver.online') === 0) {
      window.location.href = 'https://www.tengineserver.online' + href.substring('https://www.tengineserver.online'.length); 
      return;
    }

  // crazydoes URLS 
  // - nothing - crazydoes home page
  // - keyword (azure_auth, password_reset, support, account)
  // - event/eventid - crazydoes events
  // - file_picker/siteid - filepicker for that site
  // - userid - a user's dashboard (accessible by anyone)
  // - userid?code= - when linking stripe
  // - userid/storyurl - a user's story

  var content;
  hrefLower = stripDomainFromUrl(hrefLower);
  if (hrefLower === '') {
    content = (
      <Home />
    );
  } else {
      if (hrefLower.indexOf('azure_auth/') === 0) {
          console.log('azure_auth url');
      content = (
        <AzureAuth href={href}/>  // pass in original href to get original params - i.e. code
      );
    } else if (hrefLower === 'password_reset') {
      content = (
        <PasswordReset />
      );
    } else if (hrefLower === 'support') {
      content = (
        <SupportView />
      );  
    } else if (hrefLower === 'faqs') {
      content = (
        <FAQView />
      );  
    } else if (hrefLower === 'terms') {
      content = (
        <TermsView />
      );  
    } else if (hrefLower === 'privacy') {
      content = (
        <PrivacyView />
      );  
   } else if (hrefLower === 'account') {
      content = (
        <AccountView />
      );
    } else if (hrefLower.indexOf('file_picker/') === 0) {
      if (wispData.checkedToken === true) {
        if (wispData.userSitesLoaded === true) {
          //  if it's not in there then go get it
          var i = hrefLower.indexOf('file_picker');
          var siteUrl = hrefLower.substring(i+12);
          var site = getSiteFromUrl(siteUrl, wispData.userSitesAuth);
          content = (
            <FilePickerView site={site} />
          );
        }
      }
    } else {
      var event = getEventFromUrl(hrefLower);
      if (event != null) {
        content = (
          <EventView event={event} />
        );
      } else {
        var userId = getUserIdFromUrl(hrefLower);
        var i = hrefLower.indexOf('/');
        if (i > 0) {  // got a site after userid
          var siteUrl = hrefLower.substring(i+1);
          // we need to know if the user's logged in - so see if we know that yet
          if (wispData.checkedToken === true) {
            if (!publicState.validToken) {    // if the user's not logged in, then just go get the site
                if (wispData.siteInfo) {
                    // does our siteInfo match the siteUrl?
                    if (wispData.siteInfo.url === siteUrl) {
                        content = (
                            <SiteView site={wispData.siteInfo} />
                        );
                    } else if (gettingSiteInfo != 'getting') {
                        getSiteInfo(siteUrl);
					          }
                } else if (gettingSiteInfo === 'init') {
                    getSiteInfo(siteUrl);
                }
            } else {
              // the user's signed in!
              // wait til sites are loaded to see if it's in there
              if (wispData.userSitesLoaded === true) {
                //  if it's not in there then go get it
                var site = getSiteFromUrl(siteUrl, wispData.userSitesAuth);
                if (site) { // got this site in our site maps
                  content = (
                    <SiteView site={site} />
                  );
                } else if (wispData.siteInfo) {
                  content = (
                    <SiteView site={wispData.siteInfo} />
                  );          
                } else if (gettingSiteInfo === 'init') {
                    getSiteInfo(siteUrl);
                }
              }
            }
          }
        } else {
          content = (
            <HomeUser userId={userId} />
          );    
        }       
      }
    }
  }

  return (
    <Elements stripe={stripePromise}>
      <BaseLayout>
        {content}
      </BaseLayout>
    </Elements>
  );
}
