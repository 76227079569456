import React, {useContext, useEffect} from 'react';
import {WispContext} from './WispProvider';
import { NextLink } from './components/NextLink.js';
// this is a page on a site, which contains boxes, which contain one or more documents. Documents may have multiple "document pages" of course
export const PageView = (props) => {

    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;
	const [forceUpdate, setForceUpdate] = React.useState(0);

    useEffect(() => {
		// var content = wispData.docContent[props.value.doc_id];
		// var el = document.getElementById(props.curPageId);
		// if (el) {
		// 	el.innerHTML = content;
		// }
	}, []);


	var cls = '';
	if (props.navOpen) {
		cls = 'float';
	}
	var h = '100%';
	var content = wispData.docContent[props.value.doc_id];

	// why set height on this div? it ends up smaller than docs and creates problem for the next doc link
	// if (wispData.windowSize) {
	// 	h = wispData.windowSize.h - 65;
	// }
	// <div dangerouslySetInnerHTML={{ __html: content }} style={{ height: h, position: 'relative' }} /> 
	// 			<div id={props.curPageId} style={{ height: h, position: 'relative' }} />


	return (
		<div id='wispPageContId' className={'wispPageContainer wispBlog ' + cls}>
			<div dangerouslySetInnerHTML={{ __html: content }} style={{ height: h, position: 'relative' }} /> 
			<NextLink siteDocMaps={wispData.siteDocMaps} curPageId={props.curPageId} value={props.value} selPanDocFN={props.selPanDocFN} />
        </div>
    );
}
  