import React, { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { Footer } from './components/Footer';
import { WispContext } from './WispProvider.js';
import {WispButton} from './components/WispButton';
import {wispHistory} from './components/WispHistory.js';
import noSqlImage from './images/nosql.png';
import sampleCodeImage from './images/sample_code.png';
import excelBarImage from './images/excel_bar.png';
import freeImage from './images/freetag.jpg';
import excelImage from './images/excel_example.png';
import mapImage from './images/map_example.png';
import wordImage from './images/word_example.png';
import crazyImage from './images/crazy_example.png';
import {home_setup} from './js/coalesce.js';
// import byuImage from './images/byu.png';

export const Home = (props) => {

  const [showAccountCreate, setShowAccountCreate] = React.useState(false);
  const [setupHome, setSetupHome] = React.useState(false);

	var wispData = useContext(WispContext);

	useEffect(() => {
    if (!setupHome) {
      document.documentElement.className="js";var supportsCssVars=function(){var e,t=document.createElement("style");return t.innerHTML="root: { --tmp-var: bold; }",document.head.appendChild(t),e=!!(window.CSS&&window.CSS.supports&&window.CSS.supports("font-weight","var(--tmp-var)")),t.parentNode.removeChild(t),e};supportsCssVars()||alert("Please view this demo in a modern browser that supports CSS Variables.");
      home_setup();
      setSetupHome(true);
    }
  }, []);

  function createAcct() {
    setShowAccountCreate(false);
    wispData.setShowCreateAccount(true);
  }


	function signinButClick() {
    wispData.setShowSignin(true);
  }

  function eventClick(eventId) {
    wispHistory.push('event/' + eventId);
  }

    var story1 = (
      <div className='crazyTile' onClick={() => eventClick('lejog')}>
        <div className='eventTextPanel'>
          <div className='eventAction'>
            No Database Required
          </div>
          <div className='eventSubAction'>
            Easy App Data uses spreadsheets, not complicated databases. Upload any Microsoft Excel sheet from your computer or Microsoft OneDrive account.
          </div>
        </div>
        <div className='bottomPanel' style={{padding: '0px 5px 5px', overflow: 'hidden'}}>
          <div className='innerBottomPanel' style={{paddingTop: '29px'}}>
            <img width='120px' src={noSqlImage} />
          </div>
        </div>
      </div>
    );

    var story2 = (
      <div className='crazyTile' onClick={() => eventClick('lejog')}>
        <div className='eventTextPanel'>
        <div className='eventAction'>
            Easy to Read Data in App
          </div>
          <div className='eventSubAction'>
            <div>
              For each spreadsheet you upload, Easy App Data provides a small code snippet to read the data in your web app or site.
            </div>
          </div>
        </div>
        <div className='story_icon_div bottomPanel' style={{padding: '5px', overflow: 'hidden'}}>
          <div className='innerBottomPanel' style={{paddingTop: '25px'}}>
            <img width='250px' height='125px' src={sampleCodeImage} />
          </div>
        </div>
      </div>
    );

    var story3 = (
      <div className='crazyTile' onClick={() => eventClick('lejog')}>
        <div className='eventTextPanel'>
        <div className='eventAction'>
            Easy Data Updates
          </div>
          <div className='eventSubAction'>
            <div>
              It's easy to change your data. Just edit and update the Excel sheet on your Easy App Data dashboard.
              No developers required.
            </div>
          </div>
        </div>
        <div className='story_icon_div bottomPanel' style={{padding: '5px', overflow: 'hidden'}}>
          <div className='innerBottomPanel' style={{paddingTop: '25px'}}>
            <img width='250px' height='125px' src={excelBarImage} />
          </div>
        </div>
      </div>
    );

    var story4 = (
      <div className='crazyTile' onClick={() => eventClick('lejog')}>
        <div className='eventTextPanel'>
          <div className='eventAction'>
            Free to Start
          </div>
          <div className='eventSubAction'>
            <div>
              Get 2500 free data views per month. Contact sales for our inexpensive volume plans.
            </div>
          </div>
        </div>
        <div className='dollar_icon bottomPanel' style={{padding: '0px 5px 5px', overflow: 'hidden'}}>
          <div className='innerBottomPanel'>
            <img width='300px' src={freeImage} style={{marginTop: '-20px'}} />
          </div>
        </div>
      </div>
    );

    var word_image = (
      <div className="box_padded_special box_3 scooch_half" style={{background: 'rgb(227,227,227)'}}>
        <div style={{textAlign: 'center', fontSize: '21px', fontWeight: '600'}} >
          INDUSTRY STANDARD WORD DOCUMENTS...
        </div>
        <img width='100%' src={wordImage} style={{marginTop: '10px'}} />
        <div className='homeArrowDiv'>
          <FontAwesomeIcon className='fa-2x closeClass' icon={faArrowDown}/>
        </div>
            <div style={{textAlign: 'center', marginTop: '25px', fontSize: '21px', fontWeight: '600'}} >
          POPULATE CONTENT IN APP...
        </div>
        <img width='100%' src={crazyImage} style={{marginTop: '10px'}} />
      </div>
    );
    var word_text = (
      <div className="box_padded box_3 scooch_1 box_vert_margin">
          <h2 className="h1">Articles Powered by Word Docs</h2>
          <p>Use Microsoft Word documents to populate content in your app. Non-developers create and edit Word documents and upload them to TEngineServer.online.</p>
      </div>
    );

    var cls = "section_gray";
    var word_content = (
      <React.Fragment>
        {word_image}
        {word_text}
      </React.Fragment>
    );
    if (wispData.mobileView) {
      cls = "";
      word_content = (
        <React.Fragment>
          {word_text}
          {word_image}
        </React.Fragment>
      );
    }

    return (
      <div id="home" className="demo-4">

        <section id="landing" className="content content--canvas">
              <div className="row flex">
                  <div className="box_padded box_3 scooch_5">
                      <h1>Power web & mobile apps with Excel & Word documents!</h1>
                      <p>Use industry-standard Microsoft Excel & Word documents to power app functionality and content. No developers required.</p>
                      <button className="primary" onClick={createAcct}>Get Started</button>
                  </div>
              </div>
        </section>

        <section className="marketing_section">
          <div className="row flex">
            <div className="box_padded box_3 scooch_1 box_vert_margin">
              <h2 className="h1">Entire Apps Powered by Excel Spreadsheets</h2>
              <p>Use data from Excel spreadsheets to control your app. Non-developers edit the Excel sheets and upload them to TEngineServer.online. It's that easy to change your app!</p>
            </div>
            <div className="box_padded_special box_3 scooch_half" style={{background: 'rgb(227,227,227)'}}>
              <div style={{textAlign: 'center', fontSize: '21px', fontWeight: '600'}} >
                DATA IN SPREADSHEET...
              </div>
              <img width='100%' src={excelImage} style={{marginTop: '10px'}} />
              <div className='homeArrowDiv'>
                <FontAwesomeIcon className='fa-2x closeClass' icon={faArrowDown}/>
              </div>
              <div style={{textAlign: 'center', marginTop: '25px', fontSize: '21px', fontWeight: '600'}} >
                POWERS APP...
              </div>
              <img width='100%' src={mapImage} style={{marginTop: '10px'}} />
            </div>
          </div>
        </section>

        <section id="features" className={"marketing_section" + cls} >
            <div className="row flex">
              {word_content}
            </div>
        </section>

        <section id="howitworks" className="marketing_section">
          <div className="row">
            <div className="box_padded box_2_5 scooch_1">
              <h2>No Database Required</h2>
              <p>TEngine uses spreadsheets, not complicated databases. Upload any Microsoft Excel sheet from your computer or Microsoft OneDrive account.</p>
            </div>
            <div className="box_padded box_2_5">
              <h2>Easy to Setup</h2>
              <p>TEngine provides a small code snippet your developers can use to read data from a spreadsheet or display Word documents hosted on your TEngine account.</p>
            </div>
            <div className="box_padded box_2_5">
              <h2>Non-Developers can Update an App</h2>
              <p>Edit Excel sheets and Word documents, upload them to TEngine and your app automatically updates.</p>
            </div>
          </div>
        </section>
        <section id="getoffice" className="office_section">
          <div className='wisp_works_hint'>
            <div className='works_cont'>
              <div className='hintTitle'>Don't have Microsoft Office?</div>
              <div className='hintSubTitle'>You can create a free Microsoft Office account which includes Word, Excel and OneDrive <a href='http://onedrive.com' target="_blank" rel="noopener noreferrer" >here</a>.</div>
            </div>
          </div>
        </section>
        <section id="footer" className="office_section">
          <Footer/>
        </section>

      </div>


    );
}


        /*
        <div id="wrapper">
          { <div className="panel1Cont">
            <div className="panel1aCont">

            </div>
            <div className="panel1bCont">
              <div className='homeWispH1'>
                <div className='homeWispH1Div'>Easy App Data</div>
              </div>
              <div className='homeWispH4'>Read data from Excel sheets in any web application</div>
              <div className='startBufCont'>
                <WispButton
                  bcls='primaryButton'
                  d='inline-block'
                  w="300"
                  js={createAcct}
                  m='0px'
                  mes="Get Started - It's Free!" />
              </div>
            </div>
    </div>  }
    <div className="banner">

    <div className="row" style={{overflow:'visible'}}>
        <div className="homeTileCont">
          {story1}
          {story2}
          {story3}
          {story4}
        </div>
    </div>
</div>

<div className='worksContainer'>
    <div className='feature_pan_title' style={{marginBottom:'80px'}}>How it Works</div>

    <div className='worksPanelCont'>
      <div className='worksPanel worksPanel1'>
        <div className='worksNumber worksNumber1'>1</div>
        <div className='worksDesc worksDesc1'>Upload a spreadsheet from your computer or Microsoft OneDrive</div>
      </div>
      <div className='works_icon_div'><FontAwesomeIcon className='works_icon' icon={faCloudUploadAlt} /></div>
    </div>
    <div className='worksPanelCont'>
      <div className='worksPanel worksPanel2'>
        <div className='worksNumber worksNumber2'>2</div>
        <div className='worksDesc worksDesc2'>Get a code snippet to read the spreadsheet</div>
      </div>
      <div className='works_icon_div'><FontAwesomeIcon className='works_icon2' icon={faCode} /></div>
    </div>
    <div className='worksPanelCont'>
      <div className='worksPanel worksPanel3'>
        <div className='worksNumber worksNumber3'>3</div>
        <div className='worksDesc worksDesc3'>Add the code snippet to your web application</div>
      </div>
      <div className='works_icon_div'><FontAwesomeIcon className='works_icon3' icon={faTv} /></div>
    </div>
</div>


</div>        
        
        <div id="wrapper">
          <div className="panel1Cont">
            <div className="panel1aCont">

            </div>
            <div className="panel1bCont">
              <div className='homeWispH1'>
                <div className='homeWispH1Div'>Easy App Data</div>
              </div>
              <div className='homeWispH4'>Read data from Excel sheets in any web application</div>
              <div className='startBufCont'>
                <WispButton
                  bcls='primaryButton'
                  d='inline-block'
                  w="300"
                  js={createAcct}
                  m='0px'
                  mes="Get Started - It's Free!" />
              </div>
            </div>
          </div>
            <div className="banner">

                <div className="row" style={{overflow:'visible'}}>
                    <div className="homeTileCont">
                      {story1}
                      {story2}
                      {story3}
                      {story4}
                    </div>
                </div>
            </div>

            <div className='worksContainer'>
                <div className='feature_pan_title' style={{marginBottom:'80px'}}>How it Works</div>

                <div className='worksPanelCont'>
                  <div className='worksPanel worksPanel1'>
                    <div className='worksNumber worksNumber1'>1</div>
                    <div className='worksDesc worksDesc1'>Upload a spreadsheet from your computer or Microsoft OneDrive</div>
                  </div>
                  <div className='works_icon_div'><FontAwesomeIcon className='works_icon' icon={faCloudUploadAlt} /></div>
                </div>
                <div className='worksPanelCont'>
                  <div className='worksPanel worksPanel2'>
                    <div className='worksNumber worksNumber2'>2</div>
                    <div className='worksDesc worksDesc2'>Get a code snippet to read the spreadsheet</div>
                  </div>
                  <div className='works_icon_div'><FontAwesomeIcon className='works_icon2' icon={faCode} /></div>
                </div>
                <div className='worksPanelCont'>
                  <div className='worksPanel worksPanel3'>
                    <div className='worksNumber worksNumber3'>3</div>
                    <div className='worksDesc worksDesc3'>Add the code snippet to your web application</div>
                  </div>
                  <div className='works_icon_div'><FontAwesomeIcon className='works_icon3' icon={faTv} /></div>
                </div>
            </div>

            <div className='wisp_works_hint'>
              <div className='works_cont'>
                <div className='hintTitle'>Don't have Microsoft Office?</div>
                <div className='hintSubTitle'>You can create a free Microsoft Office account which includes Word, Excel and OneDrive <a href='http://onedrive.com' target="_blank" rel="noopener noreferrer" >here</a>.</div>
              </div>
            </div>

            <Footer/>

      </div> */