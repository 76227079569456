import React, {useContext, useEffect} from 'react';
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import {getUserEmail} from './util/user.js';
import {getSiteFromUrl, isSiteAdmin} from './util/site.js';
import {getDateStr} from './util/base.js';
import {WispContext} from './WispProvider.js';
import {WispNotification} from './components/WispNotification';
import {AccountPanel} from './components/AccountPanel';
import $ from 'jquery';


export default function AccountView(props) {

    var wispData = useContext(WispContext);

    const [accountState, setAccountState] = React.useState({
        alertMessage: '',
        notificationMessage: '',
    })

    function setAlertMessage(val) {
        if (accountState.alertMessage != val) {
            setAccountState((prevState) => {
                return {
                    ...prevState,
                    alertMessage: val,
                }
            });
        }
    }

    function setNotificationMessage(val) {
        if (accountState.notificationMessage != val) {
            setAccountState((prevState) => {
                return {
                    ...prevState,
                    notificationMessage: val,
                }
            });
        }
    }

    useEffect(() => {
        var user = wispData.publicState.wispUser;
        if (user) {
            var url = getWispUrl(wisp_urls.user_uids_get, user.id);
            fetchGet(url, getUIDsResult, wispData);  
            url = getWispUrl(wisp_urls.user_stripeInfo_get, user.id);
            fetchGet(url, getStripeInfoResult, wispData);  
            url = getWispUrl(wisp_urls.user_charges_get, user.id);
            fetchGet(url, getChargesResult, wispData);
        }
    }, []);

    function getUIDsResult(success, data) {
        if (success) {
            wispData.setUserUids(data);
        }
    }

    function getStripeInfoResult(success, data) {
        if (success) {
            wispData.setUserStripeInfo(data);
        }
    }

    function getChargesResult(success, data) {
        if (success) {
            wispData.setUserCharges(data);
        }
    }

    function getChargesDiv() {
        var user_charges = wispData.publicState.userCharges;
        var charges;
        if (user_charges && user_charges.length > 0) {
            charges = user_charges.map(charge => {
                var datestr = getDateStr(charge.date_time,false);
                var site = getSiteFromUrl(charge.product_id, wispData.userSitesAuth);
                if (site) {        
                    return (
                        <div key={site.name}>
                            <div className='wisp_in charge_col1 charge_entry'>{datestr}</div>
                            <div className='wisp_in charge_col1 charge_entry'>{site.name}</div>
                            <div className='wisp_in charge_col2 charge_entry'>${charge.price.toFixed(2)}</div>
                        </div>
                    );
                }
            });
        } else {
            charges = (
                <div style={{marginTop:'13px',fontSize:'13px'}}>No charges yet</div>
            )
        }

        return (
            <div>       
                <div style={{color:'#FFF',textTransform: 'uppercase',fontSize: '11px'}}>
                    <div className='wisp_in charge_col1'>Date</div>
                    <div className='wisp_in charge_col1'>Item</div>
                    <div className='wisp_in charge_col2'>Amount</div>
                </div>
                {charges}
            </div>
        );
    }

    var email = getUserEmail(wispData);

    var user = wispData.publicState.wispUser;
    if (user) {
        var displayName = user.first_name + ' ' + user.last_name;

        var stripeStatus;
        var bankMessage;
        var stripeButton;
        if(!isSiteAdmin(wispData.userSitesAuth)) {
            stripeStatus = (
                <div>Status: <span style={{marginLeft:'10px',fontWeight:'600',color:'#FFF'}}>Nothing To Sell Yet</span></div>
            );
            bankMessage = (
                <p>Once you've created your own WispBook you can setup bank information to receive payments directly into your bank account.</p>
            );    
        } else {
            bankMessage = (
                <p>Setup bank information in order to receive payments for WispBooks, WispLogs and WispDocs sold.</p>
            );
            var userStripeInfo = wispData.publicState.userStripeInfo;
            if (userStripeInfo && userStripeInfo.stripe_access_token && userStripeInfo.stripe_access_token != '') {

                stripeButton = (
                    <a href='https://dashboard.stripe.com/login?redirect=/account/transfers' className='stripe-connect dark'>
                        <span>Manage Stripe Account</span>
                    </a>
                );
                stripeStatus = (
                    <div>Status: <span style={{marginLeft:'10px',fontWeight:'600',color:'#FFF'}}>You're Ready To Receive Payments</span></div>
                );               
            } else {
                var href = 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' +
                    process.env.REACT_APP_STRIPE_CLIENT_ID +
                    '&scope=read_write&stripe_user[email]=' +
                    email +
                    "&stripe_user[url]=https://www.WispBook.com" +
                    "&stripe_user[business_name]=" +
                    encodeURIComponent(displayName) +
                    "&stripe_user[product_description]=Sell online books. Customers are charged to access.";

                stripeButton = (
                    <a href={href} className='stripe-connect dark'><span>Setup Stripe Account</span></a>
                );
                stripeStatus = (
                    <div>Status: <span style={{marginLeft:'10px',fontWeight:'600',color:'#FFF'}}>Stripe Account Setup Required</span></div>
                );
            }
        }

        var chargesDiv = getChargesDiv();

        var alertDiv;
        if (accountState.alertMessage != '') {
            alertDiv = <WispNotification message={accountState.alertMessage} type='error' />
        }

        var notificationDiv;
        if (accountState.notificationMessage != '') {
            notificationDiv = (
                <div className="wisp_notification success">
                    {accountState.notificationMessage}
                </div>        
            );
        }

        return (
            <div>
                <div id="webt_ui_account" style={{verticalAlign:'top',padding: '60px 20px', marginLeft: props.leftMargin}}>
                    <div className='accountContainer'>
                        {notificationDiv}
                        <div id='accountRow2' className='ui_obox' style={{position:'relative',background:'inherit',width:'100%' }}>
                            <AccountPanel />
                            { /* <div id='acctStripe_col' style={{display:'inline-table'}}>
                                <div id='accountStripeDiv' className='webt_ui_back_18 webt_ui_color_8 acct_panel'>
                                    <div style={{marginLeft:'10px'}}>
                                        <div id="acctab_name" className='dash_title ui_h4'>
                                            <span id='nav_name_span'>Bank Information</span>
                                        </div>
                                        <div className='acct_bank_info webt_ui_color_8'>
                                            {bankMessage}
                                            <p style={{marginTop: '12px'}}>Wisp uses a 3rd party service called Stripe to process payments and distribute funds to your bank account. Stripe is a reputable service used by Target, BestBuy, Lyft, and many others.</p>
                                        </div>
                                        <div className='stripe_status' id='stripeStatusDiv'>{stripeStatus}</div>
                                        <div id='userStripeDiv'>
                                            <div style={{display:'inline-table',marginBottom:'10px',marginTop:'5px'}} id='stripeButtonDiv'>
                                                {stripeButton}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div> */ }
                        </div>   { /* end accountRow2 */ }
                        { /* <div id='accountRow3' className='ui_obox' style={{position:'relative',background:'inherit',width:'100%'}}>
                            <div id='acctCharge_col' style={{display:'inline-table'}}>
                                <div id='acctChargeDiv' className='webt_ui_back_18 webt_ui_color_8 acct_panel'>
                                    <div style={{margin:'0px 10px'}}>
                                        <div className='dash_title ui_h4'><span id='nav_name_span'>Charges</span></div>
                                        <div id='acctChargesDiv' style={{marginTop:'12px'}}>{chargesDiv}</div>
                                    </div>
                                </div>
                            </div> */ }
                            { /* <div id='acctRefer_col' style={{display:'inline-table'}}>
                                <div id='acctReferDiv' className='webt_ui_back_18 webt_ui_color_8 acct_panel'>
                                    <div style={{margin:'0px 10px'}}>
                                        <div className='dash_title ui_h4'><span id='nav_name_span'>Referrals</span></div>
                                        <div style={{marginTop:'12px',fontSize:'12px'}}>Share the referral code <b>FREEVIP</b> with teachers and they get a free VIP subscription for 1 year. The VIP subscription makes it effortless for teachers to create eTextbooks and packets that save students 50% or more.</div>
                                        <div style={{marginTop:'12px',fontSize:'12px'}}>Teachers can enter the referral code <b>FREEVIP</b> when creating their account or use the following link: <a href='https://www.WispBook.com?refer=FREEVIP' style={{color:'#a5ddf9'}}>
                                            https://www.WispBook.com?refer=FREEVIP</a>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div> */ }
                    </div>
                </div>
            </div>
        );
    }
    return (<div />);
}