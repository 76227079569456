import React, { useContext, useEffect} from 'react';
import {DashboardView} from './DashboardView.js';
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import {loadUserSites} from './util/user.js';
import {wispHistory} from './components/WispHistory.js';
import {WispNav} from './components/WispNav';
import {WispContext} from './WispProvider';
import { getCookie } from './util/base.js';
import { fileType } from './util/document.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { loadSiteDocs } from './util/site.js';
import { isForSale, goToSite, eventInProgress, upcomingEvent } from './util/site.js';

// need this out of functional component otherwise gets reset every time the component it laoded
var gettingSites = "init";
var drawTest = 0;

export default function HomeUser(props) {
    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;
    const [loaded, setLoaded] = React.useState(false);
    const [forceUpdate, setForceUpdate] = React.useState(0);
    const [userSites, setUserSites] = React.useState(null); // why storing user sites here and not wispProvider?
    const [homeUser, setHomeUser] = React.useState(null);
    const [homeAuthState, setHomeAuthState] = React.useState({
        currentView: 'dashboard',
    })

    useEffect(() => {
        if (props.userId === wispData.publicState.wispUserId) {
            setHomeUser(wispData.publicState.wispUser);
        } else {
            var url = wisp_urls.user_get + props.userId + '/';
			fetchGet(url, getUserResult, wispData);
        }

        setLoaded(true);
    }, []);

    function getUserResult(success, data) {
        if (success) {
            setHomeUser(data);
        }
    }

    function setCurrentView(val) {
        if (homeAuthState.currentView != val) {
            setHomeAuthState((prevState) => {
                return {
                    ...prevState,
                    currentView: val,
                }
            });
        }
    }

    function getUserSites() {
        gettingSites = "getting";
        // 2nd param - get the sites of this user
        // 3rd param - the user id of the logged in user/viewer, if any
        loadUserSites(wispData, props.userId, wispData.publicState.wispUserId, wispData.token, getSitesResult, true);
    }

    function getSitesResult(success, data, userId) {
        if (success && data != 'fetching') {
            setUserSites(data);
            gettingSites = "setting";
            /* var tsite = eventInProgress(data);
            if (tsite) {
                initSite(tsite);
            } */
        }
    }

    // check for stripe code - only happens when we link a Stripe account
    const href = window.location.href;
    var i = href.indexOf('code=');
    if (i > 0) {
        var i2 = href.indexOf('&', i+5);
        var code = '';
        if (i2 > i) {
            code = href.substring(i+5,i2);
        } else {
            code = href.substring(i+5);
        }
        linkStripeAccount(code);
    }

    function linkStripeAccount(code) {
        var token = window.localStorage.getItem("wisp_token");   //getCookie('wisp_token');
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            code: code,
        }

        var url = getWispUrl(wisp_urls.user_link_stripe, this.wisp_user.id);
        fetch(url, {method: 'POST', mode: 'cors', headers})
        .then(function(response) {
            console.log('response.status = ' + response.status); // Will show you the status
            return response.json();
        })
        .then(data => {
            console.log('success = ' + data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    function setView(view) {
        if (view == 'support') {
            wispHistory.push('/support');
        } else {
            setCurrentView(view);
        }
    }

    function getMainPanWisp() {
        var mainPanWisp; 
        return mainPanWisp;
    }

    function updateSites(siteurl) {
        getUserSites(siteurl);

    }

    var view;
    if (homeAuthState.currentView === 'dashboard') {
        // what sites to use? if the user's page we're on is the same as the signed in user then use publicState.userSites
        // otherwise, need to load the public sites of the specified user
        if (wispData.checkedToken === true) {
            var sites;
            if (wispData.publicState.wispUser && wispData.publicState.wispUser.id === props.userId){
                sites = wispData.publicState.userSites;
            } else if (gettingSites === 'init' && loaded) {
                getUserSites();
            }
            view = (
                <DashboardView sites={sites} thumbs={wispData.siteThumbnails} userId={props.userId} user={homeUser} updateSitesFN={updateSites} />
            )
        }
    }

    return (
        <div>
            {view}
        </div>
    );

}
  