import React, {useContext, useEffect} from 'react';
import {WispContext} from './WispProvider.js';
import { FilePicker } from './components/FilePicker';
export const FilePickerView = (props) => {
    var wispData = useContext(WispContext);

    function doneFN() {
        var newurl = process.env.REACT_APP_PROTOCOL + '://' + process.env.REACT_APP_URL_POSTFIX + '/' + wispData.publicState.wispUser.id;
        window.location.href = newurl;
    }

    return (
        <FilePicker site={props.site} itemName='Library' doneFN={doneFN} />
    )
}