import React, {createContext} from 'react';
import { getCookie } from './util/base.js';

export const WispContext = createContext();

export const WispProvider = (props) => {

    const [appSites, setAppSites] = React.useState(null);
	const [checkedToken, setCheckedToken] = React.useState(false);
    const [docContent, setDocContent] = React.useState([]);
    const [inviteSitemap, setInviteSitemap] = React.useState(null);
	const [mobileView, setMobileView] = React.useState(false);
	const [newSite, setNewSite] = React.useState({});
    const [pageDocs, setPageDocs] = React.useState({});
	const [searchBookURL, setSearchBookURL] = React.useState('');
	const [showAddPopup, setShowAddPopup] = React.useState(false);
    const [showCreateAccount, setShowCreateAccount] = React.useState(false);
	const [showNewBook, setShowNewBook] = React.useState(false);
	const [showSignin, setShowSignin] = React.useState(false);
    const [showUpdateMes, setShowUpdateMes] = React.useState(false);
    const [siteThumbnails, setSiteThumbnails] = React.useState([]);
    const [siteDocMaps, setSiteDocMaps] = React.useState([]);
    const [siteInfo, setSiteInfo] = React.useState(null);
    const [sitePages, setSitePages] = React.useState({});
    const [token, setToken] = React.useState(null);
    const [userSitesAuth, setUserSitesAuth] = React.useState(null);
    const [userSitesLoaded, setUserSitesLoaded] = React.useState(false);
	const [windowSize, setWindowSize] = React.useState({});
    const [wispDocs, setWispDocs] = React.useState({}); // mapped by id - booksettings needs this to know external_id - get and set docs as needed one at a time

    const [publicState, setPublicState] = React.useState({
        showSignin: false,
        showSubscribe: false,
        signinMessage: null,
        userCharges: null,
        userSites: null,
        userStripeInfo: null,
        userUids: null,
        validToken: false,
        wispUser: null,
        wispUserId: null,			// used when refreshing the page to verify token
    })


    function setUserCharges(val) {
        setPublicState((prevState) => {
            return {
                ...prevState,
                userCharges: val,
            }
        });
    }
    function setUserStripeInfo(val) {
        setPublicState((prevState) => {
            return {
                ...prevState,
                userStripeInfo: val,
            }
        });
    }
    function setUserUids(val) {
        setPublicState((prevState) => {
            return {
                ...prevState,
                userUids: val,
            }
        });
    }

    const showSubscribePopup = (show) => {
        if (publicState.showSubscribe != show) {
            setPublicState((prevState) => {
                return {
                    ...prevState,
                    showSubscribe: show,
                }
            });
        }
    }

    const setUserSites = (sites) => {
        setPublicState((prevState) => {
            return {
                ...prevState,
                userSites: sites,
            }
        });
    }

    const setWispUser = (user) => {
        setPublicState((prevState) => {
            return {
                ...prevState,
                wispUser: user,
            }
        });
    }

    const setWispUserId = (id) => {
        setPublicState((prevState) => {
            return {
                ...prevState,
                wispUserId: id,
            }
        });
    }

    const setValidToken = (val) => {
        if (publicState.validToken != val) {
            setPublicState((prevState) => {
                return {
                    ...prevState,
                    validToken: val,
                }
            });
        }
    }

    function setSigninMessage(message) {
        console.log('set message = ' + message);
        setPublicState((prevState) => {
            return {
                ...prevState,
                signinMessage: message,
            }
        });
    }

    return (
		<WispContext.Provider value={{
            appSites: appSites,
            checkedToken: checkedToken,
			docContent: docContent,
            inviteSitemap: inviteSitemap,
			mobileView: mobileView,
			newSite: newSite,
            searchBookURL: searchBookURL,
            setAppSites: setAppSites,
			setCheckedToken: setCheckedToken,
			setDocContent: setDocContent,
            setInviteSitemap: setInviteSitemap,
			setMobileView: setMobileView,
			setNewSite: setNewSite,
			setPageDocs: setPageDocs,
			setSearchBookURL: setSearchBookURL,
			setShowAddPopup: setShowAddPopup,
            setShowCreateAccount: setShowCreateAccount,
			setShowNewBook: setShowNewBook,
            setShowSignin: setShowSignin,
            setShowUpdateMes: setShowUpdateMes,
			setSigninMessage: setSigninMessage,
			setSiteDocMaps: setSiteDocMaps,
            setSiteInfo: setSiteInfo,
            setSitePages: setSitePages,
            setSiteThumbnails: setSiteThumbnails,
			setUserCharges: setUserCharges,
			setUserSites: setUserSites,
			setUserSitesAuth: setUserSitesAuth,
            setUserSitesLoaded: setUserSitesLoaded,
			setUserStripeInfo: setUserStripeInfo,
			setUserUids: setUserUids,
			setToken: setToken,
			setWindowSize: setWindowSize,
			setWispUser: setWispUser,
			setWispUserId: setWispUserId,
            setValidToken: setValidToken,
            setWispDocs: setWispDocs,
			showAddPopup: showAddPopup,
			showCreateAccount: showCreateAccount,
			showNewBook: showNewBook,
			showSignin: showSignin,
            showSubscribePopup: showSubscribePopup,
            showUpdateMes: showUpdateMes,
            siteInfo: siteInfo,
            siteThumbnails: siteThumbnails,
			pageDocs: pageDocs,
			publicState: publicState,
			siteDocMaps: siteDocMaps,
			sitePages: sitePages,
            token: token,
            userSitesAuth: userSitesAuth,
            userSitesLoaded: userSitesLoaded,
            windowSize: windowSize,
            wispDocs: wispDocs,
		}}>
            {props.children}
        </WispContext.Provider>
    );
}
  