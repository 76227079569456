import React, {useContext, useEffect} from 'react';
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import { getFirstPageDoc, getNextPageDoc } from './util/site.js';
import {fileType} from './util/document.js';
import {WispContext} from './WispProvider.js';
import {WispNotification} from './components/WispNotification';
import {WispNav} from './components/WispNav';
import {PageView} from './PageView';
import { Document, Page } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NextLink } from './components/NextLink';


export default function EventView(props) {

    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [curPageId, setCurPageId] = React.useState(0);
	const [curView, setCurView] = React.useState(null);
	const [site, setSite] = React.useState(null);
	const [docMapsLoaded, setDocMapsLoaded] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});
	// pdf stuff
	const [numPages, setNumPages] = React.useState(null);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [navOpen, setNavOpen] = React.useState(!wispData.mobileView);

    useEffect(() => {
        // load the site associated with this event
		var ev = props.event;
		var eurl = props.event + "event";
		if (process.env.REACT_APP_TEST === 'false') {
			if (ev === 'jmt') {
				eurl = 'jmt_2052';
			} else if (ev === 'cotswold') {
				eurl = 'cotswold_0012021';
			} else if (ev === 'nh48') {
				eurl = 'nh48_0012021';
			} else if (ev === 'lejog') {
				eurl = 'lejog_0012021';
			}
		}
		console.log('Event View: getting site by url');
		var url = wisp_urls.site_get_by_url + "?url=" + eurl;
		fetchGet(url, getSiteResult, wispData, true, '');

        if (curPageId != 0) {
            var url = getWispUrl(wisp_urls.site_docMaps_get, site.id);
            fetchGet(url, getDocMapsResult, wispData, true);
		}
		setNavOpen(!wispData.mobileView);
	}, [curPageId]);

    function getSiteResult(success, data) {
        if (success) {
            if (data.detail && data.detail === "No site exists with that url") {
                setAlertMessage("Can't find this event! Please check back later!");
            } else {
                setSite(data);
                var url = getWispUrl(wisp_urls.site_pages_get, data.id);
                fetchGet(url, getPagesResult, wispData, false, data);
            }
         }
    }

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

    function getPagesResult(success, data, site) {
        if (success) {
            // put the site pages into the dictionary of site pages
            var curValue = wispData.sitePages;
            if (typeof curValue != 'undefined') {
                curValue[site.id] = data;
            } else {
                var id = site.id;
                curValue = { id: data };
            }
            wispData.setSitePages(curValue);

            // this needs work, but for now if there's only one page then load the page docs
            if (data.length >= 1) {
                setCurPageId(data[0].id);	//hooks suck - if the fetchGet below doesn't call the backend then 
                var url = getWispUrl(wisp_urls.site_docMaps_get, site.id);
                fetchGet(url, getDocMapsResult, wispData, true, site);
            }
        }
	}

	function getDocMapsResult(success, data, site) {
		if (success) {
			wispData.setSiteDocMaps(data);
			setDocMapsLoaded(true);
			// figure out the view - if member should go to first doc in list
			if (props.docId) {
				var map = getMapFromDocId(data, props.docId);
				selPanDoc(map, data);
			} else if (site) {
				var map = getFirstPageDoc(curPageId, data);	// pass data, can't count on wispData.siteDocMaps being set yet
				selPanDoc(map, data);
			}
			setForceUpdate(forceUpdate + 1);

		}
	}

	function getMapFromDocId(maps, docId) {
		var themap;
		maps.forEach((map) => {
			if (map.doc_id === parseInt(docId)) {
				themap = map;
			}
		});
		return themap;
	}

    function selPanDoc(map, siteDocMaps) {
		if (map) {
			if (!wispData.docContent[map.doc_id] && fileType(map.filename) != 'pdf' && map.url === null) {
				var url = getWispUrl(wisp_urls.document_get, map.doc_id);
				if (wispData.mobileView) {
					url += "?view=mobile";
				} else {
					url += "?view=desktop";
				}
				url += "&format=html";
				// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
				fetchGet(url, getDocResults2, wispData, true, map, {style: 'blog' }, 'html');
			} else {
				setView({ type: 'document', value: map });
				checkForNextView(curPageId, siteDocMaps);
			}
			if (wispData.mobileView) {
				setNavOpen(false);	
			}
		}
	}

	function getDocResults2(success, data, map) {
		if (success) {
			var content = wispData.docContent;
			content[map.doc_id] = data;
			wispData.setDocContent(content);
			setView({ type: 'document', value: map });
			checkForNextView(curPageId, wispData.siteDocMaps);
		}
	}

	function checkForNextView(map, data) {
		var map = getNextPageDoc(map, data);	// pass data, can't count on wispData.siteDocMaps being set yet

	}

    function setView(view) {
        setCurView(view);
    }

	function wisp_popup_toggle() {
		setNavOpen(!navOpen);
	}

	var guardian;
	if (wispData.mobileView) {		// really, why validToken??? not getting guardian on mobile public pages    && publicState.validToken
		var guardianIcon = faArrowRight;
		var gclass = 'closed';
		if (navOpen) {
			guardianIcon = faArrowLeft;
			gclass = 'open';
		}
		guardian = (
			<div className={gclass + ' wisp_guardian'} onClick={wisp_popup_toggle} style={{top: '585px'}}>
				<div className='wisp_m_icon'>
					<FontAwesomeIcon icon={guardianIcon} />
				</div>
			</div>
		);
	}

    var title;
    if (props.event === 'nh48') {
        title = "New Hampshire's 48 4000ft+ Peaks";
    } else if (props.event === 'lejog') {
        title = "Land's End to John O' Groats";
    } else if (props.event === 'cotswold') {
        title = "The Cotswold Way";
    } else if (props.event === 'jmt') {
        title = "The John Muir Trail";
    }

	var docView;
    if (curView) {
		if (docMapsLoaded && curView.type === 'document'){
			if(curView.value.url != null && curView.value.url != '') {
				var cls = '';
				if (navOpen) {
					cls = 'float';
				}
				var url = curView.value.url;
				var i = url.lastIndexOf('/');
				if (i > 0) {
					url = 'https://www.youtube.com/embed' + url.substring(i);
				}
				docView = (
					<div id='wispPageContId' className={'wispPageContainer wispBlog ' + cls} style={{marginTop: '80px', width: '100%', height: '100%'}}>
						<iframe width='100%' height='100%' style={{ minHeight: '500px' }} src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
						<NextLink siteDocMaps={wispData.siteDocMaps} value={curView.value} curPageId={curPageId} selPanDocFN={selPanDoc} />
					</div>
				);
			} else if (fileType(curView.value.filename) !== 'pdf') {
				docView = (
					<PageView value={curView.value} navOpen={true} curPageId={curPageId} selPanDocFN={selPanDoc}  />
				);
			} else {
				var url = getWispUrl(wisp_urls.document_get, curView.value.doc_id) + '?format=native&view=mobile';
				var pages=[];
				for(var i = 0; i < numPages; i++) {
					var loadingPage = (
						<div className='pdfLoadPageDiv'>
							<FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ float: 'right', color: 'rgb(45,120,205)', marginTop: '3px', marginRight: '2px' }} />
						</div>
					);
					pages.push(
						<Page key={i+1} pageNumber={i+1} loading={loadingPage} />
					);
				}

				var file = {
					url: url,
					httpHeaders: {'Authorization': 'Bearer ' + wispData.token, 'user-id': wispData.publicState.wispUserId},
				};

				var loadingContent = (
					<div className='pdfLoadDocDiv'>{'Loading ' + curView.value.filename + '...'}</div>
			    );

				// set document style class - wispBlog or wispClassic
				docView = (
					<div id='wispPageContId' className='wispPageContainer wispBlog'>
						<Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading={loadingContent}>
							{pages}
						</Document>
					</div>
				);
			}
		}
	}    

    var tnav;
    if (site && navOpen) {
        tnav = (
			<WispNav site={site} curPageId={curPageId} setViewFn={setView} curView={curView} selPanDocFN={selPanDoc} type='Story' top='570px' />
        );
	}
	
	var nav = (
		<div style={{position: 'relative'}}>
			{tnav}
			{guardian}
		</div>
	)

	var alertDiv;
	if (alertMessage.text !== '') {
		console.log('alert = ' + alertMessage.text);
		alertDiv = (
			<div style={{ marginTop: '15px' }}>
				<WispNotification message={alertMessage.text} type={alertMessage.type} />
			</div>
		);
	}

	var nextLink;

    return (
        <div className="eventViewCont">
            <div className={"event" + props.event + "Main"} >
                <div className="eventMainTitle">
                    {title}
                </div>
                {nav}
                <div className="eventMainTextCont">
                    {docView}
                    {alertDiv}
                </div>
				{nextLink}
            </div>
        </div>
    );
}