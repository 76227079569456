import React, {useContext, useEffect} from 'react';
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import {WispContext} from './WispProvider';
import CodePopup from './CodePopup.js';
import {getDateStr} from './util/base.js';
import {wispHistory} from './components/WispHistory.js';
import { DocManager } from './components/DocManager';
import {isForSale, goToSite, eventInProgress, upcomingEvent} from './util/site.js';
import {userHasSetupStripe} from './util/user.js';
import { WispMenu } from './components/WispMenu';
import {loadUserSites} from './util/user.js';

export const DashboardView = (props) => {

	var forceUpdateCount = 0;
	var newUrl = '';

    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [showCodePopup, setShowCodePopup] = React.useState(false);
	const [codeDoc, setCodeDoc] = React.useState({});
	const [docMapsLoaded, setDocMapsLoaded] = React.useState(false);
	const [refreshingDocMaps, setRefreshingDocMaps] = React.useState(false);
	const [removeSite, setRemoveSite] = React.useState(null);

    useEffect(() => {
		if (publicState.wispUser) {
			var url = getWispUrl(wisp_urls.user_stripeInfo_get, wispData.publicState.wispUser.id);
			fetchGet(url, getStripeInfoResult, wispData);
			/* setTEngineHtmlToDiv(5684376524292096, 'desktop', 'blog');
			setTEngineHtmlToDiv(5698136135696384, 'desktop', 'blog');
			setTEngineHtmlToDiv(5643580454469632, 'desktop', 'blog');

			var url = 'https://api.tengine.io/document/5684376524292096?format=cells';
			fetch(url, {method: 'GET', mode: 'cors'})
			.then(function(response) {
				return response.json();
			})
			.then(data => {
				var cells = data['cells'];
				for (var row = 0;row < cells.length;row++) {
					var rowCells = cells[row];
					var rowVals = 'row ' + row + ' = ';
					for (var col = 0; col < 10; col++) {
						rowVals += getColVal(rowCells, col) + ', ';
					}
					console.log(rowVals);
				}
			})
			.catch((error) => {
					console.log('error getting ' + 5684376524292096 + ': ' + error);
			}); */

		}
		if (props.sites && props.sites.length > 0) {
			site = props.sites[0];
			if (!refreshingDocMaps) {
				setRefreshingDocMaps(true);
				url = getWispUrl(wisp_urls.site_docMaps_get, site.id);
				fetchGet(url, getDocMapsResult, wispData, true);
			}

		}		

    }, [props.sites]);

	function getDocMapsResult(success, data) {
		if (success) {
			wispData.setSiteDocMaps(data);
			setDocMapsLoaded(true);
		}
		setRefreshingDocMaps(false);
	}

	// docId is doc's id in TEngine.io (provided below)
	// view is either 'desktop' or 'mobile'
	// style is 'classic' (render like doc appears in Word) or 'blog' (some styles are set using css)
	function setTEngineHtmlToDiv(docId, view, style) {
		var url = 'https://api.tengineserver.online/document/' + docId + '?view=' + view + '&format=html';
		fetch(url, {method: 'GET', mode: 'cors', headers: {style: style}})
		.then(function(response) {
			return response.text();
		})
		.then(data => {
			document.getElementById(docId).innerHTML = data;
		})
		.catch((error) => {
			console.log('error getting ' + docId + ': ' + error);
		});
	}
	
	function getColVal(row, col){
		for (var i = 0;i <= col; i++) {
			var nCol = getColNum(row, i);
			if (nCol === col) {
				return row['cols'][i]['v'];
			}
		}
		return '';
	}
	function getColNum(row, col) {
		var nCol = -1;
		var c = row['cols'][col];
		if (c) {
			var cVal = c['c'];
			if (cVal) {
				cVal = cVal.charCodeAt(0);
				if (col < 26) {
					nCol = cVal - 65;
				} else {
					nCol = row['cols'][col]['c'].charCodeAt(1) - 65;
				}
			}
		}	
		return nCol;
	}
	
    function getStripeInfoResult(success, data) {
        if (success) {
            wispData.setUserStripeInfo(data);
        }
    }

	function newBookDiv() {
		if (props.userId === wispData.publicState.wispUserId) {
			return (
				<div className='wisp_in wisp_dash_item' onClick={createFN}>
					<div style={{ height: '155px', lineHeight:'133px',border:'3px solid #CCC',width:'115px',textAlign:'center',fontSize:'50px',color:'#CCC'}}>
						+
					</div>
					<div style={{ fontSize: '11px', textAlign: 'center', color: '#CCC', marginTop: '2px' }}>
						Add New
					</div>
				</div>
			);
		}
		return null;
	}

    function getBookExpireText(site) {
        var str = '';
        var today = new Date();  
        var time_now = today.getTime();
        var time = site.user_access.end_date;
        if(time < time_now){
            return (
                <span style={{color:'#880000'}}>
                    Expired: {getDateStr(time,false,false)}
                </span>
            );
        }
        return (
            <React.Fragment>
                Expires: {getDateStr(time,false,false)}
            </React.Fragment>
        );    
	}

	function getSitesResult(success, data, userId) {
		if (success && data != 'fetching') {
			wispData.setUserSites(data);
			wispHistory.push('/' + userId + '/' + newUrl);
		}
	}


	function createFN() {
		var user = wispData.publicState.wispUser;
		var siteData = {
			access: 'public',
			agreed: false,
			app_id: process.env.REACT_APP_ID,
			author_fee: 0,
			date_start: '',
			date_end: '',
			description: user.first_name + '\'s story',
			name: user.first_name + '\'s story',
			price: 0,
			url: user.wisp_id,
		};
		var token = wispData.token;
		var headers = {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json',
			'user-id': user.id,
			'boxes': 'title,library',
			'app-id': process.env.REACT_APP_ID,
			'app-data': {type: 'Other'},
		}
		var url = wisp_urls.site_create;
		fetch(url, { method: 'POST', mode: 'cors', headers: headers, body: JSON.stringify(siteData) })
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				// reload sites here and then redirect to new site
				newUrl = data['url']
				loadUserSites(wispData, props.userId, wispData.publicState.wispUserId, wispData.token, getSitesResult, true);
				//				wispData.setShowNewBook(true);
				// props.updateSitesFN(data['url']);
				// var url = getWispUrl(wisp_urls.user_sites_get, publicState.wispUserId);
				// fetchGet(url, getSitesResult, wispData, true, data.url, {'app-id': process.env.REACT_APP_ID});  
			})
			.catch((error) => {
				console.error('Error:', error);
			});
//		wispData.setNewSite(site);
	}

	/* function getSitesResult(success, data, url) {
		if (success) {
			wispData.setUserSites(data);
			wispHistory.push('/' + url);
		}
	} */

	function closeAddPopup() {
		wispData.setShowAddPopup(false);
	}

	function getDocResults(success, data, map) {
		if (success) {
			var content = props.thumbs;
			content[map.doc_id] = data;
			wispData.setSiteThumbnails(content);
		}
    }
    
	function getThumbnail(site_id) {
		props.thumbs[site_id] = 'fetching';
		setTimeout(function () { wispData.setSiteThumbnails(props.thumbs) }, 50); // react doesn't like setting state in render so use timeout
		var url = getWispUrl(wisp_urls.site_thumbnail_get, site_id);
		fetchGet(url, getThumbnailResult, wispData, false, site_id, {}, 'html');
	}

	function getThumbnailResult(success, data, site_id) {
		if (success) {
			var thumbs = props.thumbs;
			thumbs[site_id] = data;
            wispData.setSiteThumbnails(thumbs);
            forceUpdateCount = forceUpdateCount + 1;
			setForceUpdate(forceUpdateCount);   // can't rely on the forceUpdate value itself because this thing can get called twice before forceUpdate updates
		}
	}

	function handleMenuItem(key, id) {
		var selectedSite;
		if (props.sites) {
			props.sites.forEach((site) => {
				if (site.id === id) {
					selectedSite = site;
				}
			});
		}
		if (key === 'settings') {
			if (props.sites) {
				props.sites.forEach((site) => {
					if (site.id === id) {
						goToSite(selectedSite.url, publicState.wispUserId);
					}
				});
			}
		}
	}

	function getCodeFN(docId, filename) {
		setCodeDoc({id: docId, name: filename});
		setShowCodePopup(true);
	}

	function closeCodePopup() {
		setShowCodePopup(false);
	}

    var user = wispData.publicState.wispUser;

	var site;
	var docManager;
	if (props.sites && props.sites.length > 0 && !refreshingDocMaps) {
		site = props.sites[0];
		docManager = (
			<DocManager
				new={false}
				site={site}
				azure_client_id='f8dc80ed-416f-4061-ac41-5b894413cc08'
				type='Site'
				label='Documents'
				accept='.pdf, .docx, .xlsx, .jpg, .jpeg, .png, .mov'
				custom='get code'
				customFN={getCodeFN}
				addContent
				/>
		);
	}

	var codePopup;
	if (showCodePopup) {
		codePopup = (
			<CodePopup closeFN={closeCodePopup} codeDoc = {codeDoc} />
		);
	}

    return (
        <div style={{marginTop: '80px'}}>
			<div className="webt_ui_dashboard" >
				{codePopup}
                <p id="dashboard_intro2" className='panel_title'>{user ? user.first_name + ' ' + user.last_name : ''}</p>
				{docManager}
				<div id='5684376524292096' className='wispBlog' />
				<div id='5698136135696384' className='wispBlog' />
				<div id='5643580454469632' className='wispBlog' />
            </div>
        </div>
    );
}
